import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from './img/logo2.png'
import Menu from './img/menu.svg'
import styles from './navigation.module.css'

const Div = styled.div`
  position: absolute;
  top: 64px;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 9;
  font-size: 16px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* box-shadow: 3px 2px 11px -4px rgba(0, 0, 0, 0.67); */
  padding: 15px;
  border-radius: 5px;
  z-index: 99999;

  & > a {
    font-family: 'GT Walsheim Pro Bold';
  }

  a:hover {
    color: #aeafb7;
  }

  ul {
    list-style-type: circle;
  }
  @media (max-width: 600px) {
    & {
      position: unset;
      display: block;
      box-shadow: none;
      padding: 0;
    }
    & > a {
      display: block;
    }
  }
`

const Down = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #000;
  position: relative;
  margin-left: -2px;
`

export default ({ location }) => {
  const [show, setShow] = React.useState(false)
  const [test, setTest] = React.useState(false)
  const [move, setMove] = React.useState(false)
  const [showMobile, setShowMobile] = React.useState(false)

  React.useEffect(() => {
    function handleScroll(e) {
      const scrollY = window.scrollY
      if (scrollY > 0) setMove(true)
      if (scrollY === 0) setMove(false)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleClick = (e) => {
    setTest(!test)
  }

  const isMemory = () => {
    if (location.pathname) {
      if (
        location.pathname.includes('/memory') ||
        location.pathname.includes('catalog') ||
        location.pathname.includes('war') ||
        location.pathname.includes('plan') ||
        location.pathname.includes('simf') ||
        location.pathname.includes('alushta')
      ) {
        return true
      }
    } else {
      if (
        location.includes('/memory') ||
        location.includes('catalog') ||
        location.includes('war') ||
        location.includes('plan') ||
        location.includes('simf') ||
        location.includes('alushta')
      ) {
        return true
      }
    }
  }

  return (
    <nav role="navigation">
      <div
        style={{
          background: move ? '#ffffff' : '#ffffff55',
          boxShadow: move && '0 0 10px rgba(0,0,0,0.2)',
        }}
      >
        <div className={styles.mobileNav}>
          <div>
            <img src={Logo} alt="" />
            <img
              onClick={() => setShowMobile(!showMobile)}
              className={styles.menu_ico}
              src={Menu}
              alt=""
            />
          </div>

          <ul data-show={showMobile} className={styles.navigation}>
            <li
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={Logo} alt="" />
            </li>
            {isMemory() ? (
              <>
                <li className={styles.navigationItem}>
                  <Link to="/">Домой</Link>
                </li>
                <li className={styles.navigationItem}>
                  <Link to="/memory">О проекте</Link>
                </li>
                <li className={styles.navigationItem}>
                  <Link to="/interactive-plan">
                    Интерактивный план захоронений
                  </Link>
                </li>

                <li className={styles.navigationItem}>
                  <div
                    className={styles.catalog}
                    onClick={() => setShow(!show)}
                  >
                    Каталоги памятников &nbsp;
                    <Down />
                  </div>
                  {show && (
                    <Div>
                      <Link to="/catalog">
                        Объекты культурного наследия Старорусского кладбища
                      </Link>
                      <Link to="/war">
                        Объекты военно-мемориального наследия Республики Крым
                      </Link>
                      <Link to="/simf">
                        Объекты Воинского кладбища Симферополя
                      </Link>
                      <Link to="/alushta">
                        Объекты культурного наследия города Алушта
                      </Link>
                    </Div>
                  )}
                </li>
              </>
            ) : (
              <>
                <li className={styles.navigationItem}>
                  <Link to="/">Домой</Link>
                </li>
                <li className={styles.navigationItem}>
                  <div className={styles.catalog} onClick={handleClick}>
                    Проекты &nbsp;
                    <Down />
                  </div>
                  {test && (
                    <Div>
                      <Link to="/project-1">Изучай, Действуй, Сохраняй</Link>
                      <ul>
                        <li>
                          <Link to="/educate">Образовательная программа</Link>
                        </li>
                        <li>
                          <Link to="/contest">Конкурс проектов</Link>
                        </li>
                        <li>
                          <Link to="/practical-seminar">
                            Практический семинар
                          </Link>
                        </li>
                        <li>
                          <Link to="/methodical">Методические материалы</Link>
                        </li>
                        <li>
                          <Link to="/training-events">
                            Обучающие мероприятия
                          </Link>
                        </li>
                      </ul>
                      <Link to="/volunteer">Волонтерская деятельность</Link>
                      <ul>
                        <li>
                          <Link to="/volunteer-news">Новости проекта</Link>
                        </li>
                        <li>
                          <Link to="/volunteer-manual">
                            Методические материалы проекта
                          </Link>
                        </li>
                        <ul>
                          {/* <li>
                            <Link to="/volunteer-videos">Вебинары обучающего курса</Link>
                          </li>
                          <li>
                            <Link to="/volunteer-programm">
                              Программа практического семинара
                            </Link>
                          </li>
                          <li>
                            <Link to="/404">Аналитический бюллетень</Link>
                          </li> */}
                          <li>
                            <Link to="/anonses">Анонсы</Link>
                          </li>
                        </ul>
                      </ul>
                      <Link>Место памяти</Link>
                      <ul>
                        <li>
                          <Link to="/place-of-memory">Маршруты</Link>
                        </li>
                        <li>
                          <Link to="/methodical-memory">
                            Методические материалы
                          </Link>
                        </li>
                      </ul>
                    </Div>
                  )}
                </li>
                <li className={styles.navigationItem}>
                  <Link to="/events">Мероприятия</Link>
                </li>
                <li className={styles.navigationItem}>
                  <Link to="/news">Новости</Link>
                </li>
                <li className={styles.navigationItem}>
                  <Link to="/404">Анонсы</Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  )
}
